import React from 'react';
import * as messageTypes from './message-types';
import {
  MESSAGE_ERROR,
  MESSAGE_IMPORTANT,
  MESSAGE_INFO,
} from '../../../common/messages/framework/messages-types';
import ActivateSubscriptionsLink from '../../containers/activate-subscriptions-link';
import ToHTML from '../../../common/components/to-html/to-html';
import Interpolate from '../../../common/components/interpolate/interpolate';

export default {
  [messageTypes.ASSETS_ARE_UPLOADING]: {
    content: (t) => <ToHTML html={t('post-form.uploading')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DELETE]: {
    content: (t) => <ToHTML html={t('messages.post-deleted')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UPDATE]: {
    content: (t) => <ToHTML html={t('messages.post-updated')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_INVALID]: {
    content: (t) => <ToHTML html={t('messages.post-invalid')} />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.POST_TOO_LARGE]: {
    content: (t) => <ToHTML html={t('messages.post-too-large')} />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.POST_ENABLE_COMMENTING]: {
    content: (t) => <ToHTML html={t('messages.commenting-enabled')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_ENABLE_ANSWERING]: {
    content: (t) => <ToHTML html={t('messages.answering-enabled')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DISABLE_COMMENTING]: {
    content: (t) => <ToHTML html={t('messages.commenting-disabled')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_DISABLE_ANSWERING]: {
    content: (t) => <ToHTML html={t('messages.answering-disabled')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_PIN]: {
    content: (t) => <ToHTML html={t('messages.post-pinned')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UNPIN]: {
    content: (t) => <ToHTML html={t('messages.post-unpinned')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_ADD_SOLVED_COMMENT]: {
    content: (t) => <ToHTML html={t('messages.post-solved-comment-added')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_REMOVE_SOLVED_COMMENT]: {
    content: (t) => <ToHTML html={t('messages.post-solved-comment-removed')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.USER_SUBSCRIBED]: {
    content: (t) => <ToHTML html={t('messages.user-subscribed')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.USER_UNSUBSCRIBED]: {
    content: (t) => <ToHTML html={t('messages.user-unsubscribed')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_SUBSCRIBED]: {
    content: (t) => <ToHTML html={t('messages.post-subscribed')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_UNSUBSCRIBED]: {
    content: (t) => <ToHTML html={t('messages.post-unsubscribed')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_MOVE]: {
    content: (t) => <ToHTML html={t('messages.post-moved')} />,
    type: MESSAGE_INFO,
  },

  [messageTypes.COMMENT_UPDATE]: {
    content: (t) => <ToHTML html={t('messages.comment-updated')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.ANSWER_UPDATE]: {
    content: (t) => <ToHTML html={t('messages.answer-updated')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.REPLY_UPDATE]: {
    content: (t) => <ToHTML html={t('messages.reply-updated')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.COMMENT_DELETE]: {
    content: (t) => <ToHTML html={t('messages.comment-deleted')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.ANSWER_DELETE]: {
    content: (t) => <ToHTML html={t('messages.answer-deleted')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.REPLY_DELETE]: {
    content: (t) => <ToHTML html={t('messages.reply-deleted')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.COMMENT_INVALID]: {
    content: (t) => <ToHTML html={t('messages.comment-invalid')} />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.COMMENT_TOO_LARGE]: {
    content: (t) => <ToHTML html={t('messages.comment-too-large')} />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.REQUIRE_POST_FIELDS]: {
    content: (t) => {
      return <ToHTML html={t('messages.post-fields-required')} />;
    },
    type: MESSAGE_ERROR,
  },
  [messageTypes.CATEGORY_DELETE]: {
    content: (t) => <ToHTML html={t('messages.category-deleted')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UPDATE]: {
    content: (t) => <ToHTML html={t('messages.category-updated')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_SUBSCRIBED]: {
    content: (t) => <ToHTML html={t('messages.category-subscribed')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UNSUBSCRIBED]: {
    content: (t) => <ToHTML html={t('messages.category-unsubscribed')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_UNSUBSCRIBED]: {
    content: (t) => <ToHTML html={t('messages.category-unsubscribed')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.CATEGORY_CREATED]: {
    content: (t) => <ToHTML html={t('messages.category-created')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.MAIN_PAGE_UPDATED]: {
    content: (t) => <ToHTML html={t('messages.main-page-updated')} />,
    type: MESSAGE_INFO,
  },

  [messageTypes.RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY]: {
    content: (t) => <ToHTML html={t('messages.rce-file-download-will-begin-shortly')} />,
    type: MESSAGE_INFO,
  },
  [messageTypes.LINK_COPIED_SUCCESS]: {
    content: (t) => <ToHTML html={t('share-link.link-copied')} />,
    type: MESSAGE_IMPORTANT,
  },
  [messageTypes.LINK_COPIED_FAILURE]: {
    content: (t) => <ToHTML html={t('share-link.link-copied-failure')} />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.POST_LINK_COPIED]: {
    content: (t) => <ToHTML html={t('messages.post-link-copied')} />,
    type: MESSAGE_IMPORTANT,
  },
  [messageTypes.POST_LIKE_SUCCESS]: {
    content: (t) => <ToHTML html={t('a11y-lives-messages.post-like-success')} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.COMMENT_INCREMENT_LIKE_COUNT_SUCCESS]: {
    content: (t) => <ToHTML html={t('a11y-lives-messages.comment-like-success')} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.COMMENT_CREATE_SUCCESS]: {
    content: (t) => <ToHTML html={t('a11y-lives-messages.comment-publish-success')} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_CREATE_SUCCESS]: {
    content: (t) => <ToHTML html={t('a11y-lives-messages.post-create-success')} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.CATEGORY_RANKS_UPDATE_SUCCESS]: {
    content: (t) => <ToHTML html={t('a11y-lives-messages.category-ranks-update-success')} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_FORM_TITLE_REQUIRED]: {
    content: (t) => <ToHTML html={t('post-form.require-title')} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_FORM_CATEGORY_REQUIRED]: {
    content: (t) => <ToHTML html={t('post-form.require-category')} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_FORM_UPLOAD_IN_PROGRESS]: {
    content: (t) => <ToHTML html={t('post-form.uploading')} />,
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.SEARCH_SEARCH_SUCCESS]: {
    content: (t, { posts }) => {
      const results = posts.length;
      if (results) {
        return t('a11y-lives-messages.search-results', { count: results });
      } else {
        return <ToHTML html={t('a11y-lives-messages.search-no-results')} />;
      }
    },
    type: MESSAGE_INFO,
    isHidden: true,
  },
  [messageTypes.POST_LIMIT_REACHED]: {
    content: (t) => <ToHTML html={t('messages.post-limit-reached')} />,
    type: MESSAGE_ERROR,
  },
  [messageTypes.COMMENT_LIMIT_REACHED]: {
    content: (t, { isReply, isDiscussion }) => {
      const key = isReply
        ? 'messages.reply-limit-reached'
        : isDiscussion
        ? 'messages.comment-limit-reached'
        : 'messages.answer-limit-reached';
      return <ToHTML html={t(key)} />;
    },
    type: MESSAGE_ERROR,
  },
  [messageTypes.ACTIVATE_SUBSCRIPTIONS]: {
    content: () => (
      <Interpolate i18nKey="messages.activate-subscriptions" link={<ActivateSubscriptionsLink />} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.EXTERNAL_HOOK_REJECT]: {
    content: (t, { message }) => {
      return message.message ? message.message : message;
    },
    type: MESSAGE_ERROR,
  },
  [messageTypes.SITE_STORAGE_FULL]: {
    content: (t) => <ToHTML html={t('messages.site-storage-full')} />,
    type: MESSAGE_ERROR,
    showWithoutTimeout: true,
  },
  [messageTypes.ALL_CATEGORIES_SUBSCRIBED]: {
    content: (t) => (
      <ToHTML
        dataHook={messageTypes.ALL_CATEGORIES_SUBSCRIBED}
        html={t('messages.all-categories-subscribed')}
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.ALL_CATEGORIES_UNSUBSCRIBED]: {
    content: (t) => (
      <ToHTML
        dataHook={messageTypes.ALL_CATEGORIES_UNSUBSCRIBED}
        html={t('messages.all-categories-unsubscribed')}
      />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.MARKED_ALL_AS_READ]: {
    content: (t) => (
      <ToHTML dataHook={messageTypes.MARKED_ALL_AS_READ} html={t('messages.marked-all-as-read')} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.MARKED_AS_READ]: {
    content: (t) => (
      <ToHTML dataHook={messageTypes.MARKED_ALL_AS_READ} html={t('messages.marked-as-read')} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.MARKED_AS_UNREAD]: {
    content: (t) => (
      <ToHTML dataHook={messageTypes.MARKED_ALL_AS_READ} html={t('messages.marked-as-unread')} />
    ),
    type: MESSAGE_INFO,
  },
  [messageTypes.POST_REPORTED]: {
    content: t => (
      <ToHTML dataHook={messageTypes.POST_REPORTED} html={t('messages.post-reported')} />
    ),
    type: MESSAGE_INFO,
  },
};
