import React from 'react';

export const LogoutIcon = (props) => (
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" {...props}>
    <path
      d="M43.293 29H38v-1h5.293l-2.145-2.144c-.195-.195-.195-.511 0-.706.195-.196.512-.196.707 0l3.352 3.35-3.352 3.354c-.195.195-.512.195-.707 0-.195-.195-.195-.512 0-.707L43.293 29zM36 32h-5c-.552 0-1-.448-1-1v-2c0-2.565 1.61-4.753 3.873-5.612C32.748 22.68 32 21.428 32 20c0-2.21 1.79-4 4-4s4 1.79 4 4c0 1.427-.748 2.68-1.873 3.388-.288.185-.664.389-1.127.612v-1.171c1.165-.412 2-1.522 2-2.829 0-1.657-1.343-3-3-3s-3 1.343-3 3c0 1.307.835 2.417 2 2.829V24.1c-2.282.464-4 2.481-4 4.9v2h5v1z"
      transform="translate(-24 -12)"
    />
  </svg>
);
