export const POST_DELETE = 'post-delete';
export const POST_PIN = 'post-pin';
export const POST_UNPIN = 'post-unpin';
export const POST_SUBSCRIBED = 'post-subscribe';
export const POST_UNSUBSCRIBED = 'post-unsubscribe';
export const POST_ENABLE_COMMENTING = 'post-enable-commenting';
export const POST_ENABLE_ANSWERING = 'post-enable-answering';
export const POST_DISABLE_COMMENTING = 'post-disable-commenting';
export const POST_DISABLE_ANSWERING = 'post-disable-answering';
export const POST_UPDATE = 'post-update';
export const POST_MOVE = 'post-move';
export const POST_ADD_SOLVED_COMMENT = 'post-add-solved-comment';
export const POST_REMOVE_SOLVED_COMMENT = 'post-remove-solved-comment';
export const POST_INVALID = 'post-invalid';
export const POST_TOO_LARGE = 'post-too-large';
export const POST_REPORTED = 'post-reported';
export const MARKED_ALL_AS_READ = 'marked-all-as-read';
export const MARKED_AS_READ = 'marked-as-read';
export const MARKED_AS_UNREAD = 'marked-as-unread';

export const COMMENT_UPDATE = 'comment-update';
export const ANSWER_UPDATE = 'answer-update';
export const REPLY_UPDATE = 'reply-update';
export const COMMENT_DELETE = 'comment-delete';
export const ANSWER_DELETE = 'answer-delete';
export const REPLY_DELETE = 'reply-delete';
export const COMMENT_INVALID = 'comment-invalid';
export const COMMENT_TOO_LARGE = 'comment-too-large';

export const REQUIRE_POST_FIELDS = 'require-post-fields';

export const CATEGORY_DELETE = 'category-delete';
export const CATEGORY_UPDATE = 'category-update';
export const CATEGORY_SUBSCRIBED = 'category-subscribed';
export const CATEGORY_UNSUBSCRIBED = 'category-unsubscribed';
export const CATEGORY_CREATED = 'category-created';
export const CATEGORY_ACCESS_UPDATED = 'category-access-updated';
export const ALL_CATEGORIES_SUBSCRIBED = 'all-categories-subscribed';
export const ALL_CATEGORIES_UNSUBSCRIBED = 'all-categories-unsubscribed';

export const USER_SUBSCRIBED = 'user-subscribe';
export const USER_UNSUBSCRIBED = 'user-unsubscribe';

export const MAIN_PAGE_UPDATED = 'main-page-updated';
export const ASSETS_ARE_UPLOADING = 'assets-are-uploading';

export const RCE_FILE_DOWNLOAD_WILL_BEGIN_SHORTLY = 'file-download-will-begin-shortly';

export const LINK_COPIED_SUCCESS = 'link-copied-success';
export const LINK_COPIED_FAILURE = 'link-copied-failure';
export const POST_LINK_COPIED = 'post-link-copied';
export const POST_LIKE_SUCCESS = 'post-like-success';
export const COMMENT_INCREMENT_LIKE_COUNT_SUCCESS = 'increment-comment-like-success';
export const COMMENT_CREATE_SUCCESS = 'comment-create-success';
export const POST_CREATE_SUCCESS = 'post-create-success';
export const SEARCH_SEARCH_SUCCESS = 'search-fetch-success';
export const CATEGORY_RANKS_UPDATE_SUCCESS = 'category-ranks-update-success';

export const POST_LIMIT_REACHED = 'post-limit-reached';
export const COMMENT_LIMIT_REACHED = 'comment-limit-reached';

export const POST_FORM_TITLE_REQUIRED = 'post-form-title-require';
export const POST_FORM_CATEGORY_REQUIRED = 'post-form-category-require';
export const POST_FORM_UPLOAD_IN_PROGRESS = 'post-form-upload-in-progress';

export const ACTIVATE_SUBSCRIPTIONS = 'activate-subscriptions';
export const EXTERNAL_HOOK_REJECT = 'external-hook-reject';

export const SITE_STORAGE_FULL = 'site-storage-full';
