import { FC, ReactNode, useEffect, useRef, useState, useCallback, useMemo } from 'react';
import * as React from 'react';
import classNames from 'classnames';
import { classes } from './navigation.st.css';
import { ChevronLeft, ChevronRight } from 'wix-ui-icons-common';
// @ts-expect-error
import withDirection, { DIRECTIONS } from 'react-with-direction';
import forDirection from '../../hoc/for-direction';
import { VerticalSeparator } from '../separator';
import { flowRight } from 'lodash';
import { getHowManyItemsCanBeShown, isListFullyShown, moveListTo, setRef } from '.';
import { isSSR } from '../../../common/store/basic-params/basic-params-selectors';
import { RootState } from '../../types/store-types';
import { connect } from '../../../common/components/runtime-context';

type NavigationItem = {
  id?: string;
  title?: string;
  postfix?: ReactNode;
};

type NavigationProps = {
  className: string;
  items: NavigationItem[];
  activeItemIndex: number;
  onItemClick: (index: number) => void;
  direction?: DIRECTIONS;
  isSsr: boolean;
};

const Navigation: FC<NavigationProps> = ({
  className,
  items,
  activeItemIndex,
  onItemClick,
  direction,
  isSsr,
}) => {
  const [itemsList, setItemsList] = useState<HTMLUListElement | null>(null);
  const [itemsListContainer, setItemsListContainer] = useState<HTMLDivElement | null>(null);
  const [shownItems, setShownItems] = useState({ from: -1, to: -1 });
  const lastUpdatedAtWidth = useRef<number>(-1);

  const moveTo = useMemo(() => {
    if (!itemsList) {
      return (moveToIndex: number) => {};
    }
    return moveListTo(direction, itemsList);
  }, [itemsList, direction]);

  const setShownItemsFromIndex = useMemo(() => {
    if (!itemsListContainer || !itemsList) {
      return (start: number) => {};
    }
    return (start: number) => {
      const canShowItemsAmount = getHowManyItemsCanBeShown(itemsListContainer, itemsList, start);
      if (start + canShowItemsAmount === shownItems.to) {
        return;
      }
      setShownItems({
        from: start,
        to: start + canShowItemsAmount,
      });
    };
  }, [itemsListContainer, itemsList, shownItems.to]);

  useEffect(() => {
    if (!itemsList || !itemsListContainer || lastUpdatedAtWidth.current !== -1) {
      return;
    }
    lastUpdatedAtWidth.current = itemsListContainer.clientWidth;
    setShownItemsFromIndex(0);
  }, [itemsList, itemsListContainer, setShownItemsFromIndex]);

  useEffect(() => {
    if (!itemsList || !itemsListContainer) {
      return;
    }
    const resizeObserver = new ResizeObserver(() => {
      const startFrom = shownItems.from === -1 ? 0 : shownItems.from;
      if (lastUpdatedAtWidth.current === itemsListContainer.clientWidth) {
        return;
      }
      lastUpdatedAtWidth.current = itemsListContainer.clientWidth;
      setShownItemsFromIndex(startFrom);
    });
    resizeObserver.observe(itemsListContainer);

    return () => {
      resizeObserver.disconnect();
    };
  }, [itemsList, itemsListContainer, setShownItemsFromIndex, shownItems.from]);

  const itemsListContainerRef = useCallback(setRef<HTMLDivElement | null>(setItemsListContainer), [
    setItemsListContainer,
    setRef,
  ]);
  const itemsListRef = useCallback(setRef<HTMLUListElement | null>(setItemsList), [
    setItemsListContainer,
    setRef,
  ]);

  const onKeyDown = (e: React.KeyboardEvent<HTMLAnchorElement>, index: number) => {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      onItemClick(index);
    }
  };

  const onArrowClick = (index: number) => {
    if (!itemsList || !itemsListContainer) {
      return;
    }
    setShownItemsFromIndex(index);
    moveTo(index);
  };

  const isMaxItemsShown = shownItems.to >= items.length;
  const onClick = () => (isMaxItemsShown ? onArrowClick(0) : onArrowClick(shownItems.to));
  const isFullyShown =
    isListFullyShown(shownItems.from, shownItems.to, items.length) || shownItems.from === -1;
  const chevronClassNames = classNames(
    'button-hover-fill',
    'breadcrumbs-icon-fill',
    classes.chevron,
  );
  const chevron = isMaxItemsShown ? (
    <ArrowLeftIconRtl className={chevronClassNames} />
  ) : (
    <ArrowRightIconRtl className={chevronClassNames} />
  );

  return (
    <div className={classes.container}>
      <div className={classes.navContainer} ref={itemsListContainerRef}>
        <nav className={className}>
          <ul className={classes.wrapper} data-hook="navigation-items-list" ref={itemsListRef}>
            {items.map((item, index) => (
              <li>
                <a
                  href="#"
                  data-hook={`navigation-${item.id}`}
                  id={item.id}
                  onClick={(e) => {
                    e.preventDefault();
                    onItemClick(index);
                  }}
                  onKeyDown={(e) => onKeyDown(e, index)}
                  className={classNames({
                    [classes.item]: true,
                    [classes.activeItem]: activeItemIndex === index,
                  })}
                >
                  {item.title}
                  {item.postfix}
                </a>
              </li>
            ))}
          </ul>
        </nav>
      </div>
      {!isFullyShown && !isSsr && (
        <div className={classes.iconWrapper}>
          <VerticalSeparator />
          <button
            className={classNames(classes.iconButton, 'button-hover-fill')}
            onClick={onClick}
            tabIndex={-1}
          >
            {chevron}
          </button>
        </div>
      )}
    </div>
  );
};

const ArrowLeftIconRtl = forDirection(ChevronLeft, ChevronRight);
const ArrowRightIconRtl = forDirection(ChevronRight, ChevronLeft);

const mapRuntimeToProps = (state: RootState) => ({
  isSsr: isSSR(state),
});

export default flowRight(connect(mapRuntimeToProps), withDirection)(Navigation);
