import { findKey, get } from 'lodash';
import {
  IS_RCE_IMAGE_ENABLED_PATH,
  IS_RCE_HTML_ENABLED_PATH,
  IS_RCE_VIDEO_ENABLED_PATH,
  IS_RCE_DIVIDER_ENABLED_PATH,
  IS_RCE_CODE_BLOCK_ENABLED_PATH,
  IS_RCE_GIPHY_ENABLED_PATH,
  IS_RCE_EMOJI_ENABLED_PATH,
  IS_SOCIAL_SHARING_LINKS_ENABLED_PATH,
  FORUM_STRUCTURE_PATH,
  POST_SORTING_PATH,
  IS_RCE_FILE_UPLOAD_ENABLED_PATH,
  CARD_SIZE_PATH,
  IS_AUTO_SUGGESTIONS_ENABLED_PATH,
  IS_UPLOAD_LIMITER_ENABLED_PATH,
  UPLOAD_SIZE_LIMIT_PATH,
  IS_PINTEREST_PIN_ENABLED_PATH,
  IS_RCE_COLLAPSIBLE_LIST_ENABLED_PATH,
  IS_RCE_POLLS_ENABLED_PATH,
  getAppSettingsValue,
  PLUGINS,
  FORUM_STRUCTURE_REGULAR,
  mapToAppSettings,
  SORT_BY_LAST_ACTIVITY,
  CARD_SIZE_LARGE,
  CARD_SIZE_MEDIUM,
  EXPERIMENT_POLLS_PLUGIN,
} from '@wix/communities-forum-client-commons';

import { getCategories } from '../../common/selectors/categories-selectors';

import { getStyleParams } from '../../common/store/style-params/style-params-selectors';

import * as appSettingSelectors from '@wix/communities-forum-client-commons';
import { getIsMobile } from '../../common/store/basic-params/basic-params-selectors';
import { getAttachmentSizeLimit } from './forum-data-selectors';
import { isExperimentEnabled } from './experiments-selectors';

import { getVisibleVerticalEmbeds } from './vertical-embed-selectors';

export const getIsHeaderEnabled = appSettingSelectors.getIsHeaderEnabled({ getIsMobile });

export const getHeaderHeight = appSettingSelectors.getHeaderHeight({ getIsMobile });

export const getMainPageHeaderHeight = appSettingSelectors.getMainPageHeaderHeight({ getIsMobile });

export const getCategoryTextAlignment = appSettingSelectors.getCategoryTextAlignment({
  getIsMobile,
});

export const getMainPageHeaderTextAlignment = appSettingSelectors.getMainPageHeaderTextAlignment({
  getIsMobile,
});

export const getIsMainPageHeaderEnabled = appSettingSelectors.getIsMainPageHeaderEnabled({
  getIsMobile,
});

export const getIsShowSubcategoriesEnabled = appSettingSelectors.getIsShowSubcategoriesEnabled({
  getIsMobile,
});

export const getIsForumStructureRegular = (style) => {
  return (
    getAppSettingsValue({ style, key: FORUM_STRUCTURE_PATH, fallback: FORUM_STRUCTURE_REGULAR }) ===
    FORUM_STRUCTURE_REGULAR
  );
};

// returns string value of sorting constant, example: 'sortByBestMach'
export const getPostSorting = (style) => {
  const numberValue = getAppSettingsValue({
    style,
    key: POST_SORTING_PATH,
    fallback: mapToAppSettings[SORT_BY_LAST_ACTIVITY],
  });
  return findKey(mapToAppSettings, (s) => s === String(numberValue));
};

export const getIsMainPageEnabled = (state, style) => {
  const isForumRegular = getIsForumStructureRegular(style);
  const totalCategories = getCategories(state).length;
  return isForumRegular && totalCategories > 1;
};

export const getIsCategorySelectEnabled = (state, style) => {
  const isForumFeed = !getIsForumStructureRegular(style);
  const totalCategories = getCategories(state).length;
  return isForumFeed && totalCategories > 1;
};

export const getIsLoginWidgetEnabled = appSettingSelectors.getIsLoginWidgetEnabled({ getIsMobile });

export const getIsViewsCountEnabled = appSettingSelectors.getIsViewsCountEnabled({ getIsMobile });

export const getIsListHashtagsEnabled = appSettingSelectors.getIsListHashtagsEnabled({
  getIsMobile,
});

export const getIsSocialSharingLinksEnabled = (state, style) =>
  getAppSettingsValue({
    style,
    key: IS_SOCIAL_SHARING_LINKS_ENABLED_PATH,
    fallback: true,
  });

export const getIsPinterestPinEnabled = (state, style) =>
  getAppSettingsValue({
    style,
    key: IS_PINTEREST_PIN_ENABLED_PATH,
    fallback: false,
  });

export const getIsBadgesEnabled = appSettingSelectors.getIsBadgesEnabled({ getIsMobile });

export const getEnabledRcePlugins = (state, style) =>
  [
    getAppSettingsValue({ style, key: IS_RCE_IMAGE_ENABLED_PATH, fallback: true })
      ? PLUGINS.IMAGE
      : null,
    getAppSettingsValue({ style, key: IS_RCE_VIDEO_ENABLED_PATH, fallback: true })
      ? PLUGINS.VIDEO
      : null,
    getAppSettingsValue({ style, key: IS_RCE_DIVIDER_ENABLED_PATH, fallback: true })
      ? PLUGINS.DIVIDER
      : null,
    getAppSettingsValue({ style, key: IS_RCE_HTML_ENABLED_PATH, fallback: false })
      ? PLUGINS.HTML
      : null,
    getAppSettingsValue({ style, key: IS_RCE_CODE_BLOCK_ENABLED_PATH, fallback: true })
      ? PLUGINS.CODE_BLOCK
      : null,
    getAppSettingsValue({ style, key: IS_RCE_GIPHY_ENABLED_PATH, fallback: true })
      ? PLUGINS.GIPHY
      : null,
    getAppSettingsValue({ style, key: IS_RCE_FILE_UPLOAD_ENABLED_PATH, fallback: true })
      ? PLUGINS.FILE_UPLOAD
      : null,
    getAppSettingsValue({ style, key: IS_RCE_EMOJI_ENABLED_PATH, fallback: true })
      ? PLUGINS.EMOJI
      : null,
    getVisibleVerticalEmbeds(state, style).length ? PLUGINS.VERTICAL_EMBED : null,
    getAppSettingsValue({ style, key: IS_RCE_COLLAPSIBLE_LIST_ENABLED_PATH, fallback: true })
      ? PLUGINS.COLLAPSIBLE_LIST
      : null,
    isExperimentEnabled(state, EXPERIMENT_POLLS_PLUGIN) &&
    getAppSettingsValue({ style, key: IS_RCE_POLLS_ENABLED_PATH, fallback: true })
      ? PLUGINS.POLLS
      : null,
  ].filter((p) => p !== null);

export const getIsSimilarPostsEnabled = appSettingSelectors.getIsSimilarPostsEnabled({
  getIsMobile,
});

export const getIsFooterPostsEnabled = appSettingSelectors.getIsFooterPostsEnabled({ getIsMobile });

export const getStyle = (state) => ({ styleParams: getStyleParams(state) });

export const getCardSize = (state, style) => {
  const cardSize = get(style, CARD_SIZE_PATH);
  return cardSize === CARD_SIZE_LARGE ? CARD_SIZE_MEDIUM : cardSize;
};

export const getIsAutoSuggestionsEnabled = (state, style) =>
  getAppSettingsValue({ style, key: IS_AUTO_SUGGESTIONS_ENABLED_PATH, fallback: true });

const isAttachmentSizeLimitEnabledInBm = (state) => getAttachmentSizeLimit(state) !== undefined;

export const getIsUploadLimiterEnabled = (state, style) => {
  const attachementSizeInBm = getAttachmentSizeLimit(state);

  if (attachementSizeInBm === null) {
    return false;
  }

  return (
    isAttachmentSizeLimitEnabledInBm(state) ||
    getAppSettingsValue({
      style,
      key: IS_UPLOAD_LIMITER_ENABLED_PATH,
      fallback: true,
    })
  );
};

export const getUploadSizeLimit = (state, style) => {
  if (isAttachmentSizeLimitEnabledInBm(state)) {
    return getAttachmentSizeLimit(state);
  }

  return getAppSettingsValue({ style, key: UPLOAD_SIZE_LIMIT_PATH, fallback: 50 });
};
