import { flowRight } from 'lodash';
import React from 'react';
import PropTypes from 'prop-types';

import { Loader } from '@wix/communities-forum-client-commons/components';
import { connect } from '../../../common/components/runtime-context';
import styles from './global-loader.scss';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';

class GlobalLoader extends React.Component {
  shouldComponentUpdate(prevProps) {
    return this.props.isVisible !== prevProps.isVisible;
  }
  render() {
    return this.props.isVisible ? (
      <div data-hook="global-loader" className={styles.container}>
        <Loader />
      </div>
    ) : (
      this.props.children
    );
  }
}

GlobalLoader.propTypes = {
  children: PropTypes.node,
  isVisible: PropTypes.bool,
};

const mapRuntimeToProps = (state) => ({
  isVisible: state.globalLoader.isVisible,
});

export default flowRight(connect(mapRuntimeToProps, [REDUCERS.GLOBAL_LOADER]))(GlobalLoader);
