import { URI as uri } from '@wix/communities-forum-client-commons';
import createAction from '../../common/services/create-action';

export const FETCH_COMMENT_REQUEST = 'comment/FETCH_REQUEST';
export const FETCH_COMMENT_SUCCESS = 'comment/FETCH_SUCCESS';
export const FETCH_COMMENT_FAILURE = 'comment/FETCH_FAILURE';

export const fetchCommentRequest = createAction(FETCH_COMMENT_REQUEST);
export const fetchCommentSuccess = createAction(FETCH_COMMENT_SUCCESS);
export const fetchCommentFailure = createAction(FETCH_COMMENT_FAILURE);

export const fetchComment = ({ postId, postType, commentId }) => {
  return (dispatch, getState, { request }) => {
    dispatch(fetchCommentRequest({ postId }));

    const promise = request(uri(`/posts/${postId}/comments/${commentId}`));
    return promise
      .then((comment) => {
        return dispatch(fetchCommentSuccess({ comment, postId, postType }));
      })
      .catch(() => dispatch(fetchCommentFailure({ postId })))
      .then(() => promise);
  };
};
