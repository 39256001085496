import { connect } from '../../../common/components/runtime-context';
import SearchInput from '../../components/search-input';
import { encodeQuery } from '../../services/query-encoding';

const mapRuntimeToProps = (state, ownProps, actions) => ({
  onClick: (query) =>
    actions.navigateWithinForum(`/search${query ? `/${encodeQuery(query)}` : ''}`),
});

export default connect(mapRuntimeToProps)(SearchInput);
