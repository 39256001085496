import { get } from 'lodash';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from '../../../common/components/runtime-context';
import { SEARCH_RESULTS } from '../../constants/page-names';
import { decodeQuery, encodeQuery } from '../../services/query-encoding';
import { MIN_QUERY_LENGTH } from '../../actions/search';
import forDevice from '../../hoc/for-device';
import SearchDesktopHeader from './search-desktop-header';
import SearchMobileHeader from './search-mobile-header';

const Header = forDevice(SearchMobileHeader, SearchDesktopHeader);

export class SearchHeader extends Component {
  componentDidMount() {
    this.props.whenWorkerInitialized.then(() => {
      this.props.pageOpened({ page: SEARCH_RESULTS });
    });
  }

  render() {
    return <Header {...this.props} />;
  }
}

SearchHeader.propTypes = {
  query: PropTypes.string.isRequired,
  onQueryChange: PropTypes.func.isRequired,
  pageOpened: PropTypes.func,
  whenWorkerInitialized: PropTypes.func,
  params: PropTypes.object.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host, whenWorkerInitialized) => ({
  query: decodeQuery(decodeURIComponent(get(ownProps, 'params.query', ''))),
  onQueryChange: (rawQuery) => {
    actions.clearSearchResults();
    if (rawQuery && rawQuery.length >= MIN_QUERY_LENGTH) {
      const query = encodeQuery(rawQuery);
      actions.setIsLoading('search', '', true);
      actions.search({ query });
      actions.navigateWithinForum(`/search${query ? `/${query}` : ''}`);
    }
  },
  pageOpened: actions.pageOpened,
  whenWorkerInitialized,
});

export default connect(mapRuntimeToProps)(SearchHeader);
