import React from 'react';
import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import { connect } from '../../../common/components/runtime-context';
import {
  ROUTE_404,
  ROUTE_SEARCH,
  ROUTE_ACCOUNT_SUSPENDED,
  ROUTE_CATEGORY,
  ROUTE_CATEGORY_CREATE_POST,
  ROUTE_CATEGORY_CREATE_QUESTION,
  ROUTE_CREATE_POST,
  ROUTE_CREATE_QUESTION,
  ROUTE_DEV_PLAYGROUND,
  ROUTE_HOME,
  ROUTE_LOGIN,
  ROUTE_POST,
  ROUTE_POST_EDIT,
  ROUTE_COMMENT_DEEP_LINK,
  EXPERIMENT_FORUM_TABS,
} from '@wix/communities-forum-client-commons';
import App from '../app';
import { getCurrentMatch } from '../../../common/router/router-selectors';
import { getIsCategorySelectEnabled } from '../../selectors/app-settings-selectors';
import CategoriesPageHeader from '../categories-page-header';
import CategoryPageHeader from '../category-page-header';
import InternalPageHeader, {
  InternalPageDesktopHeader,
  InternalPageCategorySelectDesktopHeader,
} from '../internal-page-header';
import SearchHeader from '../search-header';
import { SearchPageFiltersBar } from '../../components/filters-bar-mobile';
import DeepLinkLoader from '../../components/deep-link-loader';
import withDeviceType from '../../hoc/with-device-type';
import HeaderTitle from '../../components/header-title';
import { DevPlayground } from '../../components/dev-playground';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import withExperiment from '../../hoc/with-experiment';
import loadable from '@loadable/component';
import LoaderBox from '../../components/loader-box';

const NotFoundPage = loadable(() =>
  import(/* webpackChunkName: "not-found-page" */ '../not-found-page'),
);
const ResultsPage = loadable(() =>
  import(/* webpackChunkName: "results-page" */ '../results-page'),
);
const LoginPage = loadable(() => import(/* webpackChunkName: "login-page" */ '../login-page'));
const AccountSuspendedPage = loadable(() =>
  import(/* webpackChunkName: "account-suspended-page" */ '../account-suspended-page'),
);
const HomePage = loadable(() =>
  import(
    /* webpackChunkName: "home-page" */
    /* webpackPrefetch: true */
    '../home-page'
  ),
);
const CategoryPage = loadable(() =>
  import(
    /* webpackChunkName: "category-page" */
    /* webpackPrefetch: true */
    '../category-page'
  ),
);
const PostPage = loadable(() =>
  import(
    /* webpackChunkName: "post-page" */
    /* webpackPrefetch: true */
    '../post-page/post-page'
  ),
);
const PostCreatePage = loadable(() =>
  import(/* webpackChunkName: "post-create-page" */ '../post-create-page'),
);
const PostEditPage = loadable(() =>
  import(/* webpackChunkName: "post-edit-page" */ '../post-edit-page'),
);

const ROUTE_COMPONENT_MAP = {
  [ROUTE_404]: ({ params }) => {
    const header = <CategoriesPageHeader params={params} />;
    return (
      <App
        onLoad={() => HomePage.preload()}
        main={<NotFoundPage fallback={<LoaderBox />} />}
        simpleHeader={header}
        simpleHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_SEARCH]: ({ params, isMobile, isForumTabsEnabled }) => {
    const header =
      isForumTabsEnabled && !isMobile ? (
        <InternalPageDesktopHeader params={params} />
      ) : (
        <SearchHeader params={params} />
      );
    const mobileHeader = isMobile ? <SearchPageFiltersBar /> : null;
    return (
      <App
        onLoad={() => PostPage.preload()}
        main={<ResultsPage fallback={<LoaderBox />} />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={header}
        menu={mobileHeader}
      />
    );
  },
  [ROUTE_LOGIN]: ({ params }) => (
    <App
      main={<LoginPage fallback={<LoaderBox />} />}
      desktopHeader={<InternalPageDesktopHeader params={params} />}
    />
  ),
  [ROUTE_ACCOUNT_SUSPENDED]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        main={<AccountSuspendedPage fallback={<LoaderBox />} />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_HOME]: ({ params, isCategorySelectEnabled, isForumTabsEnabled }) => {
    return (
      <App
        onLoad={() => {
          CategoryPage.preload();
          PostPage.preload();
        }}
        main={<HomePage fallback={<LoaderBox />} />}
        simpleHeader={<CategoriesPageHeader params={params} />}
        headroomHeaderMainPageDisabled={<CategoryPageHeader params={params} />}
        desktopHeader={
          isCategorySelectEnabled && !isForumTabsEnabled ? (
            <InternalPageCategorySelectDesktopHeader params={params} />
          ) : (
            <InternalPageDesktopHeader params={params} />
          )
        }
      />
    );
  },
  [ROUTE_CATEGORY]: ({ params, isCategorySelectEnabled, isForumTabsEnabled }) => {
    return (
      <App
        onLoad={() => {
          PostPage.preload();
          HomePage.preload();
        }}
        main={<CategoryPage />}
        headroomHeader={<CategoryPageHeader params={params} />}
        headroomHeaderMainPageDisabled={<CategoryPageHeader params={params} />}
        desktopHeader={
          isCategorySelectEnabled && !isForumTabsEnabled ? (
            <InternalPageCategorySelectDesktopHeader params={params} />
          ) : (
            <InternalPageDesktopHeader params={params} />
          )
        }
      />
    );
  },
  [ROUTE_POST]: ({ params }) => {
    const header = (
      <InternalPageHeader
        params={params}
        title={<HeaderTitle titleOverride="post-page.header-title" />}
      />
    );
    return (
      <App
        onLoad={() => {
          CategoryPage.preload();
          HomePage.preload();
        }}
        main={<PostPage fallback={<LoaderBox />} />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_CREATE_POST]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        onLoad={() => PostPage.preload()}
        main={<PostCreatePage fallback={<LoaderBox />} />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_CREATE_QUESTION]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        onLoad={() => PostPage.preload()}
        main={<PostCreatePage fallback={<LoaderBox />} />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_CATEGORY_CREATE_POST]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        onLoad={() => PostPage.preload()}
        main={<PostCreatePage fallback={<LoaderBox />} />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_CATEGORY_CREATE_QUESTION]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        onLoad={() => PostPage.preload()}
        main={<PostCreatePage fallback={<LoaderBox />} />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_POST_EDIT]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        onLoad={() => PostPage.preload()}
        main={<PostEditPage fallback={<LoaderBox />} />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_COMMENT_DEEP_LINK]: ({ params }) => {
    const header = <InternalPageHeader params={params} />;
    return (
      <App
        onLoad={() => PostPage.preload()}
        main={<DeepLinkLoader />}
        headroomHeader={header}
        headroomHeaderMainPageDisabled={header}
        desktopHeader={<InternalPageDesktopHeader params={params} />}
      />
    );
  },
  [ROUTE_DEV_PLAYGROUND]: () => {
    return <App main={<DevPlayground />} />;
  },
};

class Router extends React.PureComponent {
  render() {
    const { route, ...rest } = this.props;
    const Component = ROUTE_COMPONENT_MAP[route]
      ? ROUTE_COMPONENT_MAP[route]
      : () => <div>Route "{route}" - is not implemented</div>;
    return <Component {...rest} />;
  }
}

Router.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  isCategorySelectEnabled: PropTypes.bool.isRequired,
  isForumTabsEnabled: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions, host) => {
  const routing = getCurrentMatch(state);
  return {
    route: routing.route,
    pathname: routing.pathname,
    sectionUrl: routing.sectionUrl,
    params: routing.params,
    isCategorySelectEnabled: getIsCategorySelectEnabled(state, host.style),
  };
};

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.ROUTER]),
  withDeviceType,
  withExperiment({
    isForumTabsEnabled: EXPERIMENT_FORUM_TABS,
  }),
)(Router);
