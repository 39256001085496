import { find, map, get, uniq, flatten } from 'lodash';
import { createSelector } from 'reselect';
import { getLocation } from '../store/location/location-selectors';

export const getAllCategories = (state) => state.categories || [];
export const getCategories = (state) => getAllCategories(state).filter((c) => !c.parentId);

export const getAllCategoryCount = (state) => getAllCategories(state).length;
export const getCategoryCount = (state) => getCategories(state).length;

export const getSubcategories = (state, categoryId) =>
  getAllCategories(state).filter((c) => c.parentId === categoryId);

const getCategoryId = (state, categoryId) => categoryId;

const getCategorySlug = (state, categorySlug) => categorySlug;

export const getCategory = createSelector(
  [getAllCategories, getCategoryId],
  (categories, categoryId) => find(categories, (category) => category._id === categoryId),
);

export const getParentCategory = (state, categoryId) => {
  const { parentId } = getCategory(state, categoryId) || {};
  return getCategory(state, parentId);
};

export const getCategoryBySlug = createSelector(
  [getAllCategories, getCategorySlug],
  (categories, slug) =>
    find(
      categories,
      (category) => category.slug === slug || (category.slugs && category.slugs.indexOf(slug) > -1),
    ),
);

export const getCategoryIdBySlug = (state, slug) =>
  get(getCategoryBySlug(state, slug), '_id', null);

export const getHighestRank = createSelector([getAllCategories], (categories) =>
  Math.max(-1, ...map(categories, (category) => category.rank)),
);

export const getUniquePostTypesInAllCategories = (state) =>
  uniq(flatten(getAllCategories(state).map((category) => category.postTypes)));

export const getCategoryFromLocation = (state) => {
  const categories = getAllCategories(state);
  const location = getLocation(state);
  return categories.find((c) => location.pathname.includes(c.slug)) || {};
};

export const getUnsubscribedCategories = (state) =>
  getAllCategories(state).filter((c) => !c.isSubscribed);
export const getSubscribedCategories = (state) =>
  getAllCategories(state).filter((c) => c.isSubscribed);

export const isSubscribedToAllCategories = (state) => getUnsubscribedCategories(state).length === 0;

export const getMarkedCommentConfig = (category) => category.commentsMark;
