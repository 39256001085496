import { createSelector } from 'reselect';
import { USER_PRIVACY_STATUS_PRIVATE } from '@wix/communities-forum-universal/dist/src/constants/user-privacy-status';

export const getCurrentUser = (state) => state.currentUser;

export const getCurrentUserSiteMemberId = createSelector(
  [getCurrentUser],
  (currentUser) => currentUser && currentUser.siteMemberId,
);

export const getCurrentUserSlug = createSelector(
  [getCurrentUser],
  (currentUser) => currentUser && currentUser.slug,
);

export const isOwner = createSelector([getCurrentUser], (currentUser) => {
  return currentUser ? currentUser.isOwner : false;
});

export const isBlocked = createSelector([getCurrentUser], (currentUser) =>
  Boolean(currentUser && currentUser.isBlocked),
);

export const isPrivate = createSelector(
  [getCurrentUser],
  (currentUser) => currentUser && currentUser.privacyStatus === USER_PRIVACY_STATUS_PRIVATE,
);

export const getCurrentUserRemainingPosts = createSelector(
  [getCurrentUser],
  (currentUser) => currentUser && currentUser.remainingPosts,
);
