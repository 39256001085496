import { flowRight } from 'lodash';
import PropTypes from 'prop-types';
import React from 'react';
import { EXPERIMENT_ADD_ACTIONS_MENU_BY_CTA } from '@wix/communities-forum-client-commons';
import { connect } from '../../../common/components/runtime-context';
import classNames from 'classnames';
import SearchButton from '../search-button';
import { getCategoryBySlug } from '../../../common/selectors/categories-selectors';
import withTranslate from '../../../common/components/with-translate/with-translate';
import withAuth from '../../hoc/with-auth';
import withExperiment from '../../hoc/with-experiment';
import withLayoutType from '../../hoc/with-layout-type';
import withPermissions from '../../hoc/with-permissions';
import styles from './header-buttons.scss';
import { doesUserHavePermissionsToSeeCategoryActions } from '../../containers/category-actions/category-actions-utils';
import {
  MODAL_TYPE_FORUM_ACTIONS,
  MODAL_TYPE_CATEGORY_ACTIONS,
  MODAL_TYPE_POST_ACTIONS,
} from '../modals/action-sheet/action-sheet-modal-types';
import MoreButtonMobile from '../more-button-mobile/more-button-mobile';
import MoreIcon from '../icons/more-icon';
import { getPostBySlug } from '../../selectors/post-selectors';
import { REDUCERS } from '../../../common/components/runtime-context/reducers';
import CurrentUserAvatarImage from '../../containers/current-user-avatar-image';
import AvatarImage from '../avatar-image';

export const HeaderButtons = ({
  isAuthenticated,
  isNewActionsMenuEnabled,
  isInPost,
  category = {},
  post,
  can,
  loginMember,
  buttonClicked,
}) => (
  <div className={classNames(styles.container, 'header-buttons')}>
    <div className={styles.searchButtonSpacing} data-hook="search-button">
      <SearchButton />
    </div>
    {renderActions({
      isAuthenticated,
      isNewActionsMenuEnabled,
      category,
      can,
      isInPost,
      post,
      loginMember,
      buttonClicked,
    })}
  </div>
);

function renderActions({
  isAuthenticated,
  isNewActionsMenuEnabled,
  category,
  can,
  isInPost,
  post,
  loginMember,
  buttonClicked,
}) {
  let modalType;

  if (isInPost) {
    modalType = MODAL_TYPE_POST_ACTIONS;
  } else if (category._id && doesUserHavePermissionsToSeeCategoryActions(can, category)) {
    modalType = MODAL_TYPE_CATEGORY_ACTIONS;
  } else {
    modalType = MODAL_TYPE_FORUM_ACTIONS;
  }

  if (!modalType) {
    return null;
  }

  return isNewActionsMenuEnabled ? (
    <div className={classNames(styles.actions, styles.actionsContainer, 'forum-icon-fill')}>
      {!isAuthenticated ? (
        <div
          data-hook="user-menu-button"
          onClick={() => {
            buttonClicked({ action: 'login' });
            loginMember();
          }}
        >
          <CurrentUserAvatarImage type={AvatarImage.SMALL} />
        </div>
      ) : (
        <MoreButtonMobile
          dataHook="user-menu-button"
          Icon={() => <CurrentUserAvatarImage type={AvatarImage.SMALL} />}
          type={MODAL_TYPE_FORUM_ACTIONS}
          category={category}
          post={post}
        />
      )}
    </div>
  ) : (
    <div className={classNames(styles.actions, 'forum-icon-fill')}>
      <MoreButtonMobile
        Icon={MoreIcon}
        type={modalType}
        category={category}
        post={post}
        showProfileActions
        showMarkPostsAsRead
      />
    </div>
  );
}

HeaderButtons.propTypes = {
  currentUser: PropTypes.object,
  t: PropTypes.func,
  params: PropTypes.object.isRequired,
  category: PropTypes.object,
  post: PropTypes.object,
  canRender: PropTypes.func.isRequired,
  isInPost: PropTypes.bool.isRequired,
};

const mapRuntimeToProps = (state, ownProps, actions) => ({
  isInPost: ownProps.params.postSlug !== undefined,
  loginMember: actions.requestLogin,
  buttonClicked: actions.buttonClicked,
  post: getPostBySlug(state, ownProps.params.postSlug),
  category: ownProps.params.categorySlug
    ? getCategoryBySlug(state, ownProps.params.categorySlug)
    : {},
});

export default flowRight(
  connect(mapRuntimeToProps, [REDUCERS.POSTS, REDUCERS.CATEGORIES]),
  withAuth,
  withTranslate,
  withLayoutType,
  withPermissions,
  withExperiment({
    isNewActionsMenuEnabled: EXPERIMENT_ADD_ACTIONS_MENU_BY_CTA,
  }),
)(HeaderButtons);
